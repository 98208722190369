// Configuration Settings

export var config = {
    maxQuestions: 5,
    maxAnswers: 5,

    serviceAcceptHeaderParm: 'application/vnd.awm.ampf.com+json',

    Env: 'DEV',
 
    retrieveMarketingEventInviteeByRSVPIdentifierURL: 'https://rsvp.qa.ameriprise.com/ods.svc/retrieveMarketingEventInviteeByRSVPIdentifier?RSVPId=',
    getRSVPConfigURL: 'https://rsvp.qa.ameriprise.com/ods.svc/getMarketingEventRSVPQuestionnaire?evntId=',
    saveRSVPConfigURL: 'https://rsvp.qa.ameriprise.com/ods.svc/createMarketingEventInviteeRSVPChoices',
	updateCampaignMember : 'https://rsvp.qa.ameriprise.com/ods.svc/updateFieldCRMCampaignMember'

    /*retrieveMarketingEventInviteeByRSVPIdentifierURL: 'https://ameripriseadvisorevents.qa.advisorcompass.com/ods.svc/retrieveMarketingEventInviteeByRSVPIdentifier?RSVPId=',
    getRSVPConfigURL: 'https://ameripriseadvisorevents.qa.advisorcompass.com/ods.svc/getMarketingEventRSVPQuestionnaire?evntId=',
    saveRSVPConfigURL: 'https://ameripriseadvisorevents.qa.advisorcompass.com/ods.svc/createMarketingEventInviteeRSVPChoices',
	updateCampaignMember : 'https://ameripriseadvisorevents.qa.advisorcompass.com/ods.svc/updateFieldCRMCampaignMember',
    retrieveMarketingEventRSVPChoice : 'https://ameripriseadvisorevents.qa.advisorcompass.com/ods.svc/retrieveMarketingEventInviteeRSVPChoices?evntId=',*/

}
